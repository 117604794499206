import React from 'react';
import Countdown from './Countdown';
import './ComingSoon.css';
import logo from './logo.png'; // Import the logo image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const ComingSoon = () => {
  const targetDate = new Date("2024-12-31T00:00:00");

  return (
    <div className="coming-soon">
      <div className="background-container"></div> {/* Container for background image */}
      <div className="content">
        <img src={logo} alt="Company Logo" className="logo" />
        <h1>Our Website is Coming Soon</h1>
        <Countdown targetDate={targetDate} />
        <p>Stay tuned for something amazing!</p>
        <div className="contact-details">
          <div className="contact-number">
            <p>Sri Lanka: +94 717 111 191</p>
            <span className="divider">|</span>
            <p>UAE: +971 568 794 641</p>
          </div>
          <p>Email: info@thejobbazaar.com</p>
        </div>
        <div className="social-media">
          <a href="https://www.facebook.com/thejobbazaarcom"><FontAwesomeIcon icon={faFacebookF} /></a>
          {/* <a href="https://twitter.com"><FontAwesomeIcon icon={faTwitter} /></a> */}
          <a href="https://www.instagram.com/thejobbazaar/"><FontAwesomeIcon icon={faInstagram} /></a>
        </div>
      </div>
      <footer className="footer">
        <p>&copy; 2024 Jobbazaar (Pvt) Ltd. All rights reserved. Developing by TechCubeIsland.</p>
      </footer>
    </div>
  );
};

export default ComingSoon;
